<template>
  <div class="p-1">
    <div class="card p-1">
      <div class="row match-height">
        <div class="col-md-4">
          <div
            v-clipboard:copy="message"
            class="card card-cradit p-1 cursor-pointer"
            @click="copy"
          >
            <div class="d-flex justify-content-between">
              <h3 class="text-white">
                ลิ้งค์แนะนำ
              </h3>
              <a v-clipboard:copy="message">
                <i class="fas fa-copy" />
              </a>
            </div>

            <p class="text-white">
              https://liff.line.me/1657385644-5q8k432M/register_inv/{{ user.invite_code }}
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-cradit-orange  p-1">
            <label class="h6 m-0 text-white">รวมสมัคร</label>
            <h3 class="text-white">
              {{ card.SumRegis ? card.SumRegis : 0 }} คน
            </h3>

            <!-- <p class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </p> -->
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-cradit-green p-1 text-right">
            <label class="h6 m-0 text-white">สรุปยอดแทง ( 5% )</label>
            <h4 class="text-white">
              แทง {{ card.SumBet ? (card.SumBet).toFixed(2) : 0 }}
            </h4>
            <h4 class="text-white">
              ยอดได้ {{ card.SumPercent ? (card.SumPercent).toFixed(2) : 0 }}
            </h4>

            <!-- <p class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <!-- <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="username"
                type="text"
                class="form-control"
                placeholder="ชื่อผู้ใช้งาน"
              >
            </div>
          </div> -->
          <!-- <div class="col-md-3">
            <div class="form-group">
              <label for="username">ค้นหาผู้ใช้งาน</label>
              <b-form-select
                v-model="usernameSelect"
                options="user"
              />
            </div>
          </div> -->
          <div class="col-md-5">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-gradient-dark btn-block"
              style="margin-top: 23px"
            >
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-1">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-users" /> เพื่อนที่ - แนะนำมาทั้งหมด
            </h2>
          </div>
          <div class="col d-flex justify-content-end align-items-center">
            <!-- <button
              class="btn btn-gradient-info mr-1"
              @click="detailAll"
            >
              <i class="far fa-user" /> ลูกค้าทั้งหมดของฉัน
            </button> -->
            <button
              class="btn btn-gradient-primary mr-1"
            >
              วันนี้
            </button>
            <button
              class="btn btn-gradient-primary mr-1"
            >
              เมื่อวาน
            </button>
            <!-- <i class="fas fa-chevron-double-right mr-1" /> -->
            <!-- <button
              class="btn btn-gradient-primary"
              @click="user_profile"
            >
              <i class="fas fa-user-edit" /> ดูข้อมูล
            </button> -->
          </div>
        </div>
      </div>
      <b-table
        hover
        responsive="sm"
        :items="zean"
        :fields="fields"
      >
        <template #cell(id)="data">
          <router-link :to="{name: ''}">
            {{ data.item.id }}
          </router-link>
        </template>
        <template #cell(fullname)="data">
          {{ data.item.fname + ' ' + data.item.sname }}
        </template>
        <template #cell(action)="">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
            >
              <i class="fa fa-edit" />
            </b-button>
            <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BButton, BTable,
// BFormSelect
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BTable,
    // BFormSelect,
    flatPickr,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      message: '',
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'id', label: 'id' },
        { key: 'fullname', label: 'ผู้ใช้งาน' },
        { key: 'tel', label: 'เบอร์โทรศัพท์' },
        { key: 'created_at', label: 'สมัครเมื่อ' },
        { key: 'sumamount', label: 'ยอดแทง' },
      ],
      items: [
        {
          id: '61566',
          name: '2ชม.ที่แล้ว / John Doe (awqe4as12)',
          tel: '0612345678',
          register_at: '-',
          depo_first_at: '-',
          depo_first: '-',
        },
      ],
      uid: '',
      zean: '',
      user: '',
      card: '',
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getUserslistzeandetail(this.$route.params.id)
      this.getUsers(this.$route.params.id)
      this.GetDetailCard(this.$route.params.id)
    } else {
      this.getUserslistzeandetailz(this.UserData.userID)
      this.getUsersz(this.UserData.userID)
      this.GetDetailCardZ(this.UserData.userID)
    }
  },
  methods: {
    GetDetailCard(id) {
      const params = {
        userID: id,
        // dateStart: this.dateStart,
        // dateEnd: this.dateEnd,
        // date: this.date,
      }
      this.$http
        .post('https://staffapi.ma5lotto.com/api/users/getdetailcard', params)
        .then(response => {
          this.card = response.data
        })
        .catch(error => console.log(error))
    },
    GetDetailCardZ(id) {
      const params = {
        userID: id,
        // dateStart: this.dateStart,
        // dateEnd: this.dateEnd,
        // date: this.date,
      }
      this.$http
        .post('https://staffapi.ma5lotto.com/api/usersz/getdetailcard', params)
        .then(response => {
          this.card = response.data
        })
        .catch(error => console.log(error))
    },
    getUserslistzeandetail(uid) {
      this.$http
        .get(`https://staffapi.ma5lotto.com/api/users/listzeandetail/${uid}`)
        .then(response => {
          this.zean = response.data
          // console.log(this.zean)
        })
        .catch(error => console.log(error))
    },
    getUsers(uid) {
      this.$http
        .get(`https://staffapi.ma5lotto.com/api/users/show/${uid}`)
        .then(response => {
          this.user = response.data
          // console.log(this.user)
          this.message = `https://liff.line.me/1657385644-5q8k432M/register_inv/${this.user.invite_code}`
        })
        .catch(error => console.log(error))
    },
    getUserslistzeandetailz(uid) {
      this.$http
        .get(`https://staffapi.ma5lotto.com/api/usersz/listzeandetail/${uid}`)
        .then(response => {
          this.zean = response.data
          // console.log(this.zean)
        })
        .catch(error => console.log(error))
    },
    getUsersz(uid) {
      this.$http
        .get(`https://staffapi.ma5lotto.com/api/usersz/show/${uid}`)
        .then(response => {
          this.user = response.data
          // console.log(this.user)
          this.message = `https://liff.line.me/1657385644-5q8k432M/register_inv/${this.user.invite_code}`
        })
        .catch(error => console.log(error))
    },
    user_profile() {
      this.$router.push({ name: 'user-profile' })
    },
    detailAll() {
      this.$router.push({ name: 'invite-detail-all' })
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งค์สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

  <style scoped>
  .card-b {
  background: #d8d8d842 !important;
  padding: 2px 5px !important;
}
.card-b small {
  font-size: 10px;
}
.card-cradit {
  background: linear-gradient(127.93deg, #B900FE 3.4%, #4904DE 105.43%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.white h3 {
  color: #FFFFFF !important;
}
.card-cradit-white {
  background: #FFFFFF;
  color: #000000 !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}

.card-cradit-white h3 {
  color: #000000 !important;
}

.card-cradit-blue {
  background: linear-gradient(127.93deg, #008BB4 3.4%, #4904DE 105.43%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}

.card-cradit-pink {
  background: linear-gradient(126.21deg, #FF39C9 0%, #462296 105.24%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}

.card-cradit-orange {
  background: linear-gradient(303deg, #B9443B 3.12%, #F8A748 100%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}

.card-cradit-yellow {
  background: linear-gradient(303deg, #a59400 3.12%, #f8de48 100%);
  color: #000000 !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}

.card-cradit-green {
  background: linear-gradient(303deg, #005523 3.12%, #00db0b 100%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}
.card-cradit-bluesea {
  background: linear-gradient(303deg, #005772 3.12%, #01ADE0 100%);
  color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(80, 80, 80, 0.25);
}
.card {
  border-radius: 0 !important;
}
  .darker {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #23292E !important;
  }
  .bluer {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #2E5788 !important;
  }
  .bluesea {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #1f91fc !important;
  }
  .orange {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #EF9B55 !important;
  }
  .green {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #5cc765 !important;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
